import { z } from 'zod'

import { DateStringSchema, type ZodSimplify } from '@mntn-dev/utility-types'

import { FileId } from '@mntn-dev/domain-types'
import { StructuredMetadataSchema } from './structured-metadata.ts'

const TriggeredBySchema = z.object({
  source: z.string(),
  id: z.string(),
})

const NotificationContextSchema = z.object({
  triggered_at: z.string().datetime(),
  triggered_by: TriggeredBySchema,
})

const ContextualMetaDataSchema = z.object({
  fileName: z.string().optional(),
  uniqueId: z.string().optional(),
})

const FileContextSchema = z.object({
  custom: ContextualMetaDataSchema.transform((value) => ({
    ...value,
    fileId: value.uniqueId && FileId(value.uniqueId),
  })).optional(),
})

export const CloudinaryUploadWebhookPayloadSchema = z.object({
  api_key: z.string(),
  asset_folder: z.string(),
  asset_id: z.string(),
  bytes: z.number(),
  context: FileContextSchema.optional(),
  created_at: DateStringSchema,
  display_name: z.string(),
  etag: z.string(),
  format: z.string(),
  height: z.number().optional(),
  metadata: StructuredMetadataSchema,
  notification_context: NotificationContextSchema,
  notification_type: z.string(),
  original_extension: z.string().optional(),
  original_filename: z.string().optional(),
  placeholder: z.boolean(),
  public_id: z.string(),
  request_id: z.string(),
  resource_type: z.string(),
  secure_url: z.string().url(),
  signature_key: z.string(),
  tags: z.array(z.string()),
  timestamp: DateStringSchema,
  type: z.string(),
  url: z.string().url(),
  version_id: z.string(),
  version: z.number(),
  width: z.number().optional(),
})
export type CloudinaryUploadWebhookPayload = ZodSimplify<
  typeof CloudinaryUploadWebhookPayloadSchema
>
