import {
  type AnyFunction,
  type AnyFunctionAccepting,
  type AnyValue,
  EnumBuilder,
  type HexColorString,
  type SingleOrArray,
  type ZodSimplify,
} from '@mntn-dev/utility-types'
import type { MaybeCancelled } from '../common/maybe-cancelled.ts'
import type { PreparableUploadParams } from '../common/preparable-upload-params.ts'
import type { FileResourceType } from '../common/resource.ts'
import type { UploadSource } from './upload-source.ts'
import type {
  UploadWidgetEvent,
  UploadWidgetEventName,
} from './upload-widget-events.ts'

type EncryptionKey = { key: string; iv: string }

const CroppingCoordinatesModes = ['custom', 'face'] as const
type CroppingCoordinatesMode = (typeof CroppingCoordinatesModes)[number]

export const [WidgetThemes, WidgetThemeSchema] = EnumBuilder(
  'dark',
  'default',
  'minimal',
  'office',
  'purple',
  'twilight',
  'white',
  'woody'
)

export type WidgetTheme = ZodSimplify<typeof WidgetThemeSchema>

export type WidgetFrame = {
  background: HexColorString
}

export type WidgetFonts = AnyValue

export type WidgetPallette = {
  window: HexColorString
  windowBorder: HexColorString
  tabIcon: HexColorString
  menuIcons: HexColorString
  textDark: HexColorString
  textLight: HexColorString
  link: HexColorString
  action: HexColorString
  inactiveTabIcon: HexColorString
  error: HexColorString
  inProgress: HexColorString
  complete: HexColorString
  sourceBg: HexColorString
}

export type WidgetStyles = {
  palette: WidgetPallette
  frame?: WidgetFrame
  fonts: WidgetFonts
}

export type UploadWidgetCallback = (
  error: UploadWidgetEventName | null,
  result: UploadWidgetEvent
) => void

export type Callback<T> = AnyFunctionAccepting<[T]>

export type ReturnBack<T, R = T> = (cb: Callback<R>, data: T) => void

export type PreBatchCallback =
  | ReturnBack<
      {
        files: AnyValue[]
      },
      SingleOrArray<MaybeCancelled>
    >
  | undefined

export type PrepareUploadParamsCallback =
  | ReturnBack<SingleOrArray<PreparableUploadParams>>
  | undefined

export type UploadSignatureCallback =
  | ReturnBack<Record<string, string>, string>
  | undefined

export interface UploadWidgetOptions {
  /**
   * Required parameters (according to the Cloudinary documentation)
   */
  cloudName: string
  uploadPreset?: string // not really required despite what the documentation says

  /**
   * Widget Parameters
   */
  sources?: UploadSource[]
  secure?: boolean
  encryption?: EncryptionKey
  defaultSource?: UploadSource
  multiple?: boolean
  maxFiles?: number

  /**
   * Cropping Parameters
   */
  cropping?: boolean
  showSkipCropButton?: boolean
  croppingAspectRatio?: number
  croppingDefaultSelectionRatio?: number
  croppingShowDimensions?: boolean
  croppingCoordinatesMode?: CroppingCoordinatesMode
  croppingShowBackButton?: boolean

  /**
   * Sources Parameters
   */
  dropboxAppKey?: string
  googleApiKey?: string
  searchBySites?: 'all' | string[]
  searchByRights?: boolean
  googleDriveClientId?: string

  /**
   * Upload Parameters
   */
  publicId?: string
  folder?: string
  useAssetFolderAsPublicIdPrefix?: boolean
  tags?: string[]
  resourceType?: FileResourceType
  context?: Record<string, string>
  detection?: string
  onSuccess?: AnyFunction
  uploadSignature?: string | UploadSignatureCallback
  uploadSignatureTimestamp?: number

  /**
   * Client-side Parameters
   */
  clientAllowedFormats?: string[]
  maxFileSize?: number
  maxImageFileSize?: number
  maxVideoFileSize?: number
  maxRawFileSize?: number
  maxImageWidth?: number
  maxImageHeight?: number
  validateMaxWidthHeight?: boolean
  minImageWidth?: number
  minImageHeight?: number
  croppingValidateDimensions?: boolean
  maxChunkSize?: number

  /**
   * Containing Page Parameters
   */
  form?: string
  fileName?: string
  thumbnails?: string
  thumbnailTransformation?: string | AnyValue[]

  /**
   * Customization Parameters
   */
  buttonClass?: string
  buttonCaption?: string
  theme?: WidgetTheme
  styles?: WidgetStyles
  text?: Record<string, string>

  /**
   * Advanced Parameters
   */
  showPoweredBy?: boolean
  autoMinimize?: boolean
  getTags?: (cb: Callback<string[]>, prefix: string) => void
  getUploadPresets?: (cb: Callback<string[]>) => void

  preBatch?: PreBatchCallback
  prepareUploadParams?: PrepareUploadParamsCallback
  language?: string
  showAdvancedOptions?: boolean
  showCompletedButton?: boolean
  showUploadMoreButton?: boolean
  singleUploadAutoClose?: boolean
  queueViewPosition?: string
  showInsecurePreview?: boolean
  inlineContainer?: string | HTMLElement | null
}
