import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const MetatagLambdaEnvironmentSchema = z.object({
  CLOUDINARY_CLOUD_NAME: z.string(),
  CLOUDINARY_API_KEY: z.string(),
  CLOUDINARY_API_SECRET: z.string(),
  EXIF_QF_CONFIG_PATH: z
    .string()
    .min(1)
    .catch('/opt/tagger/qf-xmp-custom-namespace.pl'),
  EXIF_TOOL_PATH: z.string().min(1).catch('/opt/bin/exiftool'),
  META_TAGGER_WEBHOOK_URL: z.string(),
  GIT_COMMIT_HASH: z
    .string()
    .regex(/^[a-f0-9]{7,40}$/, 'Invalid Git commit hash format'),

  // Required variables
  AWS_LAMBDA_FUNCTION_VERSION: z.string(),
  AWS_SESSION_TOKEN: z.string(),
  AWS_LAMBDA_LOG_GROUP_NAME: z.string(),
  AWS_EXECUTION_ENV: z.string(),
  AWS_LAMBDA_FUNCTION_NAME: z.string(),
  AWS_LAMBDA_FUNCTION_MEMORY_SIZE: z.string(),
  AWS_REGION: z.string(),
  AWS_ACCESS_KEY_ID: z.string(),
  AWS_SECRET_ACCESS_KEY: z.string(),

  // Optional variables
  AWS_LAMBDA_LOG_STREAM_NAME: z.string().optional(),
  AWS_LAMBDA_RUNTIME_API: z.string().optional(),
  AWS_XRAY_DAEMON_ADDRESS: z.string().optional(),
  AWS_XRAY_CONTEXT_MISSING: z.string().optional(),
  AWS_DEFAULT_REGION: z.string().optional(),
  PATH: z.string().optional(),
  LANG: z.string().optional(),
  TZ: z.string().optional(),
  NODE_PATH: z.string().optional(),
  NODE_EXTRA_CA_CERTS: z.string().optional(),
  LD_LIBRARY_PATH: z.string().optional(),
  PWD: z.string().optional(),
  LAMBDA_TASK_ROOT: z.string().optional(),
  LAMBDA_RUNTIME_DIR: z.string().optional(),
  SHLVL: z.string().optional(),
  _AWS_XRAY_DAEMON_ADDRESS: z.string().optional(),
  _AWS_XRAY_DAEMON_PORT: z.string().optional(),
  _HANDLER: z.string().optional(),
})

/**
 * This is the schema for the metatag lambda environment variables
 * that are returned by the lambda when it is invoked
 * with the health check flag.
 *
 * It filters out the sensitive environment variables.
 */
export const HealthCheckEnvironmentSchema = MetatagLambdaEnvironmentSchema.omit(
  {
    CLOUDINARY_API_SECRET: true,
    AWS_SESSION_TOKEN: true,
    AWS_SECRET_ACCESS_KEY: true,
  }
)

export type HealthCheckEnvironment = ZodSimplify<
  typeof HealthCheckEnvironmentSchema
>

/**
 * This is the schema for the metatag lambda environment variables
 * that reported by the metatag lambda health probe in a general health check.
 */
export const HealthCheckEnvironmentPublishSchema =
  HealthCheckEnvironmentSchema.pick({
    CLOUDINARY_CLOUD_NAME: true,
    CLOUDINARY_API_KEY: true,
    GIT_COMMIT_HASH: true,
    META_TAGGER_WEBHOOK_URL: true,
  })
