import { z } from 'zod'

import {
  FileAreaSchema,
  FileDomainInsertModelSchema,
  FileDomainQueryModelSchema,
  FileDomainSelectModelSchema,
  FileDomainUpdateModelSchema,
  FileIdSchema,
  FileTaggingStatusSchema,
  FolderUrnSchema,
  NameSchema,
  NonEmptyStringSchema,
  ProjectIdSchema,
  PublicFileAreaSchema,
  TagFilterSchema,
  UserIdSchema,
} from '@mntn-dev/domain-types'

import type { ZodSimplify } from '@mntn-dev/utility-types'
import { QuickframeTagsSchema } from './metatags/quickframe-tags.ts'
import { FileResourceTypeSchema } from './resource.ts'
import { StructuredMetadataSchema } from './structured-metadata.ts'
import {
  ImageUrlOptionsSchema,
  UrlOptionsSchema,
  VideoTransformationAndConfigAndUrlOptionsSchema,
} from './transformation-options-schema.ts'
import { UploadApiOptionsSchema } from './upload-api-options-schema.ts'

export const ValidForSecondsSchema = z
  .number()
  .int()
  .positive()
  .min(120)
  .max(36000)
export type ValidForSeconds = ZodSimplify<typeof ValidForSecondsSchema>

export const GetUploadInfoInputSchema = z.object({
  fileArea: FileAreaSchema,
  fileResourceType: FileResourceTypeSchema,
  fileName: NameSchema,
  fileId: FileIdSchema,
})

export type GetUploadInfoInput = ZodSimplify<typeof GetUploadInfoInputSchema>

export const RecordUploadInputSchema = FileDomainInsertModelSchema.partial({
  uploadStatus: true,
}).extend({
  folderUrn: FolderUrnSchema,
  fileResourceType: FileResourceTypeSchema,
  structuredMetadata: StructuredMetadataSchema.optional(),
})

export type RecordUploadInput = ZodSimplify<typeof RecordUploadInputSchema>

export const RecordPublicUploadInputSchema = RecordUploadInputSchema.extend({
  area: PublicFileAreaSchema,
})

export type RecordPublicUploadInput = ZodSimplify<
  typeof RecordPublicUploadInputSchema
>

export const CompleteUploadInputSchema = z.object({
  fileId: FileIdSchema,
})

export type CompleteUploadInput = ZodSimplify<typeof CompleteUploadInputSchema>

export const SetTagStatusInput = z.object({
  fileId: FileIdSchema,
  fileTaggingStatus: FileTaggingStatusSchema,
  projectId: ProjectIdSchema.optional(),
})

export type SetTagStatusInput = ZodSimplify<typeof SetTagStatusInput>

export const VerifyNotificationSignatureInputSchema = z.object({
  body: z.string(),
  timestamp: z.number().min(1),
  signature: z.string(),
  validFor: ValidForSecondsSchema.optional(),
})

export type VerifyNotificationSignatureInput = ZodSimplify<
  typeof VerifyNotificationSignatureInputSchema
>

export const SignApiParametersParamsSchema = z.record(z.string(), z.any())
export type SignApiParametersParams = ZodSimplify<
  typeof SignApiParametersParamsSchema
>

export const SignApiParametersInputSchema = z.object({
  fileArea: FileAreaSchema,
  params: SignApiParametersParamsSchema,
})

export type SignApiParametersInput = ZodSimplify<
  typeof SignApiParametersInputSchema
>

export const SignApiParametersPublicInputSchema =
  SignApiParametersInputSchema.extend({
    fileArea: PublicFileAreaSchema,
  })

export type SignApiParametersPublicInput = ZodSimplify<
  typeof SignApiParametersPublicInputSchema
>

export const ImageWidthSchema = z.coerce.number().positive()
export type ImageWidth = ZodSimplify<typeof ImageWidthSchema>

export const ImageHeightSchema = z.coerce.number().positive()
export type ImageHeight = ZodSimplify<typeof ImageHeightSchema>

export const GetAvatarUrlInputSchema = z.object({
  userId: UserIdSchema,
  options: ImageUrlOptionsSchema,
})

export type GetAvatarUrlInput = ZodSimplify<typeof GetAvatarUrlInputSchema>

export const GetAssetDownloadUrlInputSchema = z.object({
  fileId: FileIdSchema,
  options: UrlOptionsSchema,
})

export type GetAssetDownloadUrlInput = ZodSimplify<
  typeof GetAssetDownloadUrlInputSchema
>

export const GetImageDownloadUrlInputSchema = z.object({
  fileId: FileIdSchema,
  options: ImageUrlOptionsSchema,
})

export type GetImageDownloadUrlInput = ZodSimplify<
  typeof GetImageDownloadUrlInputSchema
>

export const GetVideoUrlInputSchema = z.object({
  fileId: FileIdSchema,
  options: VideoTransformationAndConfigAndUrlOptionsSchema,
})

export type GetVideoUrlInput = ZodSimplify<typeof GetVideoUrlInputSchema>

export const EditFileDetailsInputSchema = z.object({
  fileId: FileIdSchema,
  updates: FileDomainUpdateModelSchema.pick({
    description: true,
    title: true,
  }),
})

export type EditFileDetailsInput = ZodSimplify<
  typeof EditFileDetailsInputSchema
>

export const ArchiveFileInputSchema = z.object({
  fileId: FileIdSchema,
})

export type ArchiveFileInput = ZodSimplify<typeof ArchiveFileInputSchema>

export const GetFileListInputSchema = z.object({
  where: z.object({
    folderUrn: FolderUrnSchema,
    includeChildFolders: z.boolean().optional(),
    area: FileAreaSchema.optional(),
    search: NonEmptyStringSchema().optional(),
    tags: TagFilterSchema.optional(),
  }),
  orderBy: z
    .object({
      column: FileDomainQueryModelSchema.keyof().extract(['uploadTimestamp']),
      direction: z.enum(['asc', 'desc']),
    })
    .optional(),
})

export type GetFileListInput = ZodSimplify<typeof GetFileListInputSchema>

export const GetFileByIdInputSchema = z.object({
  fileId: FileIdSchema,
})

export type GetFileByIdInput = ZodSimplify<typeof GetFileByIdInputSchema>

export const DownloadFileInputSchema = z.object({
  fileId: FileIdSchema,
})

export type DownloadFileInput = ZodSimplify<typeof DownloadFileInputSchema>

export const OverwriteFileInputSchema = z.object({
  filePath: z.string(),
  fileId: FileIdSchema,
  options: UploadApiOptionsSchema.optional(),
})
export type OverwriteFileInput = ZodSimplify<typeof OverwriteFileInputSchema>

export const TagFileInputSchema = z.object({
  fileId: FileIdSchema,
  tags: QuickframeTagsSchema.omit({ assetFileUuid: true }),
})

export type TagFileInput = ZodSimplify<typeof TagFileInputSchema>

export const GetFileDeliverableInputSchema = z.object({
  fileId: FileIdSchema,
})

export type GetFileDeliverableInput = ZodSimplify<
  typeof GetFileDeliverableInputSchema
>

export const LinkFileInputSchema = z.object({
  source: FileDomainSelectModelSchema.pick({ fileId: true }),
  target: FileDomainInsertModelSchema.pick({
    folderUrn: true,
    area: true,
  }).partial({
    area: true,
  }),
})

export type LinkFileInput = ZodSimplify<typeof LinkFileInputSchema>
