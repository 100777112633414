import { UrlSchema } from '@mntn-dev/domain-types'
import { useEffect, useState } from 'react'

export const useScript = (url: string) => {
  const [loaded, setLoaded] = useState(false)
  const [scriptElement, setScriptElement] = useState<HTMLScriptElement | null>(
    null
  )

  useEffect(() => {
    UrlSchema.parse(url)

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.async = true

    const handleLoad = () => {
      setLoaded(true)
    }

    script.addEventListener('load', handleLoad)
    document.body.appendChild(script)
    setScriptElement(script)

    return () => {
      script.removeEventListener('load', handleLoad)
      document.body.removeChild(script)
    }
  }, [url])

  return { scriptElement, loaded }
}
