import type { MinLengthArray, ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ImageFormatSchema, VideoFormatSchema } from './enums.ts'
import { FileResourceTypeSchema } from './resource.ts'
import { TransformationOptionsSchema } from './transformation-options-schema.ts'
import { AccessModeSchema } from './upload-enums.ts'

export const RegionCoordinateSchema = z.tuple([z.number(), z.number()])
export type RegionCoordinate = ZodSimplify<typeof RegionCoordinateSchema>

export const AtleastTwoRegionCoordinatesSchema = z
  .array(RegionCoordinateSchema)
  .min(2)
  .refine(
    (arr): arr is MinLengthArray<RegionCoordinate, 2> => arr.length >= 2,
    { message: '' }
  )

export type AtleastTwoRegionCoordinates = ZodSimplify<
  typeof AtleastTwoRegionCoordinatesSchema
>

export const UploadApiOptionsSchema = z
  .object({
    access_mode: AccessModeSchema,
    allowed_formats: z.array(ImageFormatSchema).or(z.array(VideoFormatSchema)),
    async: z.boolean(),
    backup: z.boolean(),
    callback: z.string(),
    colors: z.boolean(),
    discard_original_filename: z.boolean(),
    eager: TransformationOptionsSchema,
    eager_async: z.boolean(),
    eager_notification_url: z.string(),
    eval: z.string(),
    exif: z.boolean(),
    faces: z.boolean(),
    filename_override: z.string(),
    folder: z.string(),
    format: z.union([ImageFormatSchema, VideoFormatSchema]),
    image_metadata: z.boolean(),
    media_metadata: z.boolean(),
    invalidate: z.boolean(),
    moderation: z.string(),
    notification_url: z.string(),
    overwrite: z.boolean(),
    phash: z.boolean(),
    proxy: z.string(),
    public_id: z.string(),
    quality_analysis: z.boolean(),
    resource_type: FileResourceTypeSchema,
    responsive_breakpoints: z.record(z.string(), z.array(z.number())),
    return_delete_token: z.boolean(),
    timestamp: z.number(),
    transformation: TransformationOptionsSchema,
    type: z.string(),
    unique_filename: z.boolean(),
    upload_preset: z.string(),
    use_filename: z.boolean(),
    chunk_size: z.number(),
    disable_promises: z.boolean(),
    oauth_token: z.string(),
    use_asset_folder_as_public_id_prefix: z.boolean(),
    regions: z.record(AtleastTwoRegionCoordinatesSchema),
  })
  .partial()
