import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'

export const [AccessModes, AccessModeSchema] = EnumBuilder(
  'public',
  'authenticated'
)
export type AccessMode = ZodSimplify<typeof AccessModeSchema>

export const [ModerationKinds, ModerationKindSchema] = EnumBuilder(
  'manual',
  'webpurify',
  'aws_rek',
  'metascan'
)
export type ModerationKind = ZodSimplify<typeof ModerationKindSchema>

export const [DeliveryTypes, DeliveryTypeSchema] = EnumBuilder(
  'upload',
  'private',
  'authenticated',
  'fetch',
  'multi',
  'text',
  'asset',
  'list',
  'facebook',
  'twitter',
  'twitter_name',
  'instagram',
  'gravatar',
  'youtube',
  'hulu',
  'vimeo',
  'animoto',
  'worldstarhiphop',
  'dailymotion'
)
export type DeliveryType = ZodSimplify<typeof DeliveryTypeSchema>
