import { DateStringSchema, type ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { AudioSchema } from './audio.ts'
import { FileFormatSchema } from './enums.ts'
import { FileResourceTypeSchema } from './resource.ts'
import { StructuredMetadataSchema } from './structured-metadata.ts'
import { VideoSchema } from './video/video.ts'

export const FileUploadResponseSchema = z.object({
  access_mode: z.string(),
  api_key: z.string(),
  asset_folder: z.string(),
  asset_id: z.string(),
  audio: AudioSchema.optional(),
  bit_rate: z.number().optional(),
  bytes: z.number(),
  created_at: DateStringSchema,
  display_name: z.string(),
  duration: z.number().optional(),
  etag: z.string(),
  format: FileFormatSchema,
  frame_rate: z.number().optional(),
  height: z.number().optional(),
  is_audio: z.boolean(),
  metadata: StructuredMetadataSchema.optional(),
  nb_frames: z.number().optional(),
  original_extension: z.string().optional(),
  original_filename: z.string().optional(),
  overwritten: z.boolean(),
  pages: z.number(),
  placeholder: z.boolean().optional(),
  playback_url: z.string().url().optional(),
  public_id: z.string(),
  resource_type: FileResourceTypeSchema,
  rotation: z.number(),
  secure_url: z.string(),
  signature: z.string(),
  tags: z.array(z.string()),
  thumbnail_url: z.string().url().optional(),
  type: z.string(),
  url: z.string(),
  version: z.number(),
  version_id: z.string(),
  video: VideoSchema.optional(),
  width: z.number().optional(),
})

export type FileUploadResponse = ZodSimplify<typeof FileUploadResponseSchema>
