import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'

export const [UploadSources, UploadSourceSchema] = EnumBuilder(
  'local',
  'url',
  'camera',
  'dropbox',
  'image_search',
  'shutterstock',
  'gettyimages',
  'istock',
  'unsplash',
  'google_drive'
)

export type UploadSource = ZodSimplify<typeof UploadSourceSchema>
