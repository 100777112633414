import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const FileStatsSchema = z.object({
  atime: z.date().or(z.string()),
  atimeMs: z.number(),
  birthtime: z.date().or(z.string()),
  birthtimeMs: z.number(),
  blksize: z.number(),
  blocks: z.number(),
  ctime: z.date().or(z.string()),
  ctimeMs: z.number(),
  dev: z.number(),
  gid: z.number(),
  ino: z.number(),
  mode: z.number(),
  mtime: z.date().or(z.string()),
  mtimeMs: z.number(),
  nlink: z.number(),
  rdev: z.number(),
  size: z.number(),
  uid: z.number(),
})

export type FileStats = ZodSimplify<typeof FileStatsSchema>
