import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const AudioFrequencySchema = z.union([
  z.literal(8000),
  z.literal(11025),
  z.literal(16000),
  z.literal(22050),
  z.literal(32000),
  z.literal(37800),
  z.literal(44056),
  z.literal(44100),
  z.literal(47250),
  z.literal(48000),
  z.literal(88200),
  z.literal(96000),
  z.literal(176400),
  z.literal(192000),
])

export type AudioFrequency = ZodSimplify<typeof AudioFrequencySchema>

export const [AudioCodecs, AudioCodecSchema] = EnumBuilder(
  'none',
  'aac',
  'vorbis',
  'mp3'
)
export type AudioCodec = ZodSimplify<typeof AudioCodecSchema>

export const [VideoFlags, VideoFlagSchema] = EnumBuilder(
  'animated',
  'awebp',
  'attachment',
  'streaming_attachment',
  'hlsv3',
  'keep_dar',
  'splice',
  'layer_apply',
  'no_stream',
  'mono',
  'relative',
  'truncate_ts',
  'waveform'
)
export type VideoFlags = ZodSimplify<typeof VideoFlagSchema>

export const [VideoEffect, VideoEffectSchema] = EnumBuilder(
  'accelerate',
  'reverse',
  'boomerang',
  'loop',
  'make_transparent',
  'transition'
)
export type VideoEffect = ZodSimplify<typeof VideoEffectSchema>

export const [StreamingProfiles, StreamingProfileSchema] = EnumBuilder(
  '4k',
  'full_hd',
  'hd',
  'sd',
  'full_hd_wifi',
  'full_hd_lean',
  'hd_lean'
)
export type StreamingProfile = ZodSimplify<typeof StreamingProfileSchema>

export const [ImageEffect, ImageEffectSchema] = EnumBuilder(
  'hue',
  'red',
  'green',
  'blue',
  'negate',
  'brightness',
  'auto_brightness',
  'brightness_hsb',
  'sepia',
  'grayscale',
  'blackwhite',
  'saturation',
  'colorize',
  'replace_color',
  'simulate_colorblind',
  'assist_colorblind',
  'recolor',
  'tint',
  'contrast',
  'auto_contrast',
  'auto_color',
  'vibrance',
  'noise',
  'ordered_dither',
  'pixelate_faces',
  'pixelate_region',
  'pixelate',
  'unsharp_mask',
  'sharpen',
  'blur_faces',
  'blur_region',
  'blur',
  'tilt_shift',
  'gradient_fade',
  'vignette',
  'anti_removal',
  'overlay',
  'mask',
  'multiply',
  'displace',
  'shear',
  'distort',
  'trim',
  'make_transparent',
  'shadow',
  'viesus_correct',
  'fill_light',
  'gamma',
  'improve'
)
export type ImageEffect = ZodSimplify<typeof ImageEffectSchema>

export const [ImageFlags, ImageFlagSchema] = EnumBuilder(
  'animated',
  'awebp',
  'attachment',
  'streaming_attachment',
  'hlsv3',
  'keep_dar',
  'splice',
  'layer_apply',
  'no_stream',
  'mono',
  'relative',
  'truncate_ts',
  'waveform'
)
export type ImageFlag = ZodSimplify<typeof ImageFlagSchema>

export const [ColorSpaces, ColorSpaceSchema] = EnumBuilder(
  'srgb',
  'no_cmyk',
  'keep_cmyk'
)
export type ColorSpace = ZodSimplify<typeof ColorSpaceSchema>

export const [CropModes, CropModeSchema] = EnumBuilder(
  'scale',
  'fit',
  'limit',
  'mfit',
  'fill',
  'lfill',
  'pad',
  'lpad',
  'mpad',
  'crop',
  'thumb',
  'imagga_crop',
  'imagga_scale'
)
export type CropMode = ZodSimplify<typeof CropModeSchema>

export const [Gravities, GravitySchema] = EnumBuilder(
  'north_west',
  'north',
  'north_east',
  'west',
  'center',
  'east',
  'south_west',
  'south',
  'south_east',
  'xy_center',
  'face',
  'face:center',
  'face:auto',
  'faces',
  'faces:center',
  'faces:auto',
  'body',
  'body:face',
  'adv_face',
  'adv_faces',
  'adv_eyes',
  'custom',
  'custom:face',
  'custom:faces',
  'custom:adv_face',
  'custom:adv_faces',
  'auto',
  'auto:adv_face',
  'auto:adv_faces',
  'auto:adv_eyes',
  'auto:body',
  'auto:face',
  'auto:faces',
  'auto:custom_no_override',
  'auto:none',
  'liquid',
  'ocr_text'
)
export type Gravity = ZodSimplify<typeof GravitySchema>

export const [Angles, AngleSchema] = EnumBuilder(
  'auto_right',
  'auto_left',
  'ignore',
  'vflip',
  'hflip'
)
export type Angle = ZodSimplify<typeof AngleSchema>

export const [FunctionTypes, FunctionTypeSchema] = EnumBuilder(
  'wasm',
  'remote',
  'encode'
)
export type FunctionType = ZodSimplify<typeof FunctionTypeSchema>
