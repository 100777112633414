import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import {
  HealthCheckEnvironmentPublishSchema,
  HealthCheckEnvironmentSchema,
} from './lambda-environment.ts'

/**
 * This is the schema for the response returned by the metatag lambda
 * when it is invoked with the health check flag.
 */
export const MetatagHealthCheckResultSchema = z.object({
  awsRequestId: z.string(),
  cloudinaryAccess: z.boolean(),
  environment: HealthCheckEnvironmentSchema,
  exiftoolVersion: z.string(),
  functionName: z.string(),
  functionVersion: z.string(),
  gitCommitHash: z
    .string()
    .regex(/^[a-f0-9]{7}$/, 'Invalid 7-character Git commit hash'),
  logGroupName: z.string(),
  logStreamName: z.string(),
  memoryLimitInMB: z.string(),
  timestamp: z.string(),
})

export type MetatagHealthCheckResult = ZodSimplify<
  typeof MetatagHealthCheckResultSchema
>

/**
 * This is the schema for the metatag lambda health information
 * this is reported in a general health check.
 */
export const MetatagHealthCheckPublishSchema =
  MetatagHealthCheckResultSchema.pick({
    exiftoolVersion: true,
    cloudinaryAccess: true,
    functionName: true,
    gitCommitHash: true,
    logGroupName: true,
    memoryLimitInMB: true,
    timestamp: true,
  }).extend({
    environment: HealthCheckEnvironmentPublishSchema,
  })
