import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import {
  FileFormatSchema,
  ImageFormatSchema,
  VideoFormatSchema,
} from './enums.ts'
import { FileResourceTypeSchema } from './resource.ts'
import { StorageTypeSchema } from './storage-type.ts'
import {
  AngleSchema,
  AudioCodecSchema,
  AudioFrequencySchema,
  ColorSpaceSchema,
  CropModeSchema,
  FunctionTypeSchema,
  GravitySchema,
  ImageEffectSchema,
  ImageFlagSchema,
  StreamingProfileSchema,
  VideoEffectSchema,
  VideoFlagSchema,
} from './transformation-enums.ts'

// Custom Types from transformation-options.ts
export const CustomFunctionSchema = z.object({
  function_type: FunctionTypeSchema,
  source: z.string(),
})
export type CustomFunction = ZodSimplify<typeof CustomFunctionSchema>

// CommonTransformationOptions
export const CommonTransformationOptionsSchema = z
  .object({
    raw_transformation: z.string(),
    crop: CropModeSchema,
    width: z.union([z.number(), z.string()]),
    height: z.union([z.number(), z.string()]),
    size: z.string(),
    aspect_ratio: z.union([z.number(), z.string()]),
    gravity: GravitySchema,
    x: z.union([z.number(), z.string()]),
    y: z.union([z.number(), z.string()]),
    zoom: z.union([z.number(), z.string()]),
    effect: z.union([z.string(), z.array(z.union([z.number(), z.string()]))]),
    background: z.string(),
    angle: z.union([AngleSchema, z.number(), z.coerce.number()]),
    radius: z.union([z.number(), z.string()]),
    overlay: z.union([z.string(), z.record(z.unknown())]),
    custom_function: CustomFunctionSchema,
    variables: z.array(z.union([z.string(), z.record(z.unknown())])),
    if: z.string(),
    else: z.string(),
    end_if: z.string(),
    dpr: z.union([z.number(), z.string()]),
    quality: z.union([z.number(), z.string()]),
    delay: z.union([z.number(), z.string()]),
  })
  .partial()
export type CommonTransformationOptions = ZodSimplify<
  typeof CommonTransformationOptionsSchema
>

// ImageTransformationOptions
export const ImageTransformationOptionsSchema =
  CommonTransformationOptionsSchema.extend({
    underlay: z.union([z.string(), z.record(z.unknown())]),
    color: z.string(),
    color_space: ColorSpaceSchema,
    opacity: z.union([z.number(), z.string()]),
    border: z.string(),
    default_image: z.string(),
    density: z.union([z.number(), z.string()]),
    format: ImageFormatSchema,
    fetch_format: ImageFormatSchema,
    effect: z.union([
      z.string(),
      z.array(z.union([z.number(), z.string()])),
      ImageEffectSchema,
    ]),
    page: z.union([z.number(), z.string()]),
    flags: ImageFlagSchema,
  }).partial()

export type ImageTransformationOptions = ZodSimplify<
  typeof ImageTransformationOptionsSchema
>

// VideoTransformationOptions
export const VideoTransformationOptionsSchema =
  CommonTransformationOptionsSchema.extend({
    audio_codec: AudioCodecSchema,
    audio_frequency: AudioFrequencySchema,
    video_codec: z.union([z.string(), z.record(z.unknown())]),
    bit_rate: z.union([z.number(), z.string()]),
    fps: z.union([z.string(), z.array(z.union([z.number(), z.string()]))]),
    keyframe_interval: z.string(),
    offset: z.string(),
    start_offset: z.union([z.number(), z.string()]),
    end_offset: z.union([z.number(), z.string()]),
    duration: z.union([z.number(), z.string()]),
    streaming_profile: StreamingProfileSchema,
    video_sampling: z.union([z.number(), z.string()]),
    format: VideoFormatSchema,
    fetch_format: VideoFormatSchema,
    effect: z.union([
      z.string(),
      z.array(z.union([z.number(), z.string()])),
      VideoEffectSchema,
    ]),
    flags: VideoFlagSchema,
  }).partial()

export type VideoTransformationOptions = ZodSimplify<
  typeof VideoTransformationOptionsSchema
>

// TransformationOptions
export const TransformationOptionsSchema = z.union([
  z.string(),
  z.array(z.string()),
  VideoTransformationOptionsSchema,
  ImageTransformationOptionsSchema,
  z.record(z.unknown()),
  z.array(ImageTransformationOptionsSchema),
  z.array(VideoTransformationOptionsSchema),
])

export type TransformationOptions = ZodSimplify<
  typeof TransformationOptionsSchema
>

export const ImageTagOptionsSchema = z
  .object({
    html_height: z.string(),
    html_width: z.string(),
    srcset: z.record(z.string()),
    attributes: z.record(z.string()),
    client_hints: z.boolean(),
    responsive: z.boolean(),
    hidpi: z.boolean(),
    responsive_placeholder: z.boolean(),
  })
  .partial()

export type ImageTagOptions = ZodSimplify<typeof ImageTagOptionsSchema>

const ResourceOptionsSchema = z
  .object({
    type: StorageTypeSchema,
    resource_type: FileResourceTypeSchema,
  })
  .partial()
export type ResourceOptions = ZodSimplify<typeof ResourceOptionsSchema>

export const UrlOptionsSchema = ResourceOptionsSchema.extend({
  version: z.string(),
  format: FileFormatSchema,
  url_suffix: z.string(),
}).partial()
export type UrlOptions = ZodSimplify<typeof UrlOptionsSchema>

export const AssetDownloadUrlOptionsSchema = UrlOptionsSchema.extend({
  resource_type: FileResourceTypeSchema,
})
export type AssetDownloadUrlOptions = ZodSimplify<
  typeof AssetDownloadUrlOptionsSchema
>

export const AuthTokenApiOptionsSchema = z.object({
  key: z.string(),
  acl: z.string(),
  ip: z.string().optional(),
  start_time: z.number().optional(),
  duration: z.number().optional(),
  expiration: z.number().optional(),
  url: z.string().optional(),
})

export type AuthTokenApiOptions = ZodSimplify<typeof AuthTokenApiOptionsSchema>

const ConfigOptionsSchema = z
  .object({
    cloud_name: z.string(),
    api_key: z.string(),
    api_secret: z.string(),
    api_proxy: z.string(),
    private_cdn: z.boolean(),
    secure_distribution: z.string(),
    force_version: z.boolean(),
    ssl_detected: z.boolean(),
    secure: z.boolean(),
    cdn_subdomain: z.boolean(),
    secure_cdn_subdomain: z.boolean(),
    cname: z.string(),
    shorten: z.boolean(),
    sign_url: z.boolean(),
    long_url_signature: z.boolean(),
    use_root_path: z.boolean(),
    auth_token: AuthTokenApiOptionsSchema,
    account_id: z.string(),
    provisioning_api_key: z.string(),
    provisioning_api_secret: z.string(),
    oauth_token: z.string(),
  })
  .partial()
export type ConfigOptions = ZodSimplify<typeof ConfigOptionsSchema>

export const ConfigAndUrlOptionsSchema =
  ConfigOptionsSchema.and(UrlOptionsSchema)
export type ConfigAndUrlOptions = ZodSimplify<typeof ConfigAndUrlOptionsSchema>

export const ImageTransformationAndTagsOptionsSchema =
  ImageTransformationOptionsSchema.and(ImageTagOptionsSchema)

export const ImageUrlOptionsSchema = ImageTransformationOptionsSchema.and(
  ImageTransformationAndTagsOptionsSchema
)
  .and(ConfigAndUrlOptionsSchema)
  .and(
    z.object({
      transformation: z.array(CommonTransformationOptionsSchema).optional(),
    })
  )

export type ImageUrlOptions = ZodSimplify<typeof ImageUrlOptionsSchema>

export const VideoUrlOptionsSchema = ImageTransformationOptionsSchema

export type VideoUrlOptions = ZodSimplify<typeof VideoUrlOptionsSchema>

export const TransformationAndConfigAndUrlOptionsSchema =
  TransformationOptionsSchema.and(ConfigAndUrlOptionsSchema)
export type TransformationAndConfigAndUrlOptions = ZodSimplify<
  typeof TransformationAndConfigAndUrlOptionsSchema
>
export const VideoTransformationAndConfigAndUrlOptionsSchema =
  VideoTransformationOptionsSchema.and(ConfigAndUrlOptionsSchema)

export type VideoTransformationAndConfigAndUrlOptions = ZodSimplify<
  typeof VideoTransformationAndConfigAndUrlOptionsSchema
>
