import { z } from 'zod'

import {
  type FileArea,
  type FileId,
  type FileStorageKey,
  FileStorageKeySchema,
} from '@mntn-dev/domain-types'

import type { Asset } from './common/asset.ts'
import type { VerifyNotificationSignatureInput } from './common/inputs.ts'
import type { FileResourceType } from './common/resource.ts'
import { StorageTypeSchema } from './common/storage-type.ts'
import type { StructuredMetadata } from './common/structured-metadata.ts'
import type {
  AssetDownloadUrlOptions,
  ImageUrlOptions,
  VideoTransformationOptions,
} from './common/transformation-options-schema.ts'
import { FileFolderSchema } from './configuration/index.ts'

import type { ZodSimplify } from '@mntn-dev/utility-types'
import type { FileUploadResponse } from './common/file-upload-response.ts'
import type { QuickframeTags } from './common/metatags/quickframe-tags.ts'
import type { UploadApiOptions } from './common/upload-api-options.ts'

/**
 * These types are derived from the Cloudinary API.
 * They are used to defined the `FilesAdapterInstance` interface.
 * Any file adapter must implement that interface.
 */

export const moderationTypes = ['manual', 'webpurify', 'aws_rek'] as const
export type ModerationType = (typeof moderationTypes)[number]

export type UploadOptions = {
  fileId: FileId
  validForSeconds: number
}

export const SignatureParamsSchema = z.object({
  type: StorageTypeSchema,
  folder: FileFolderSchema,
  metadata: z.string().optional(),
  public_id: FileStorageKeySchema,
  timestamp: z.number(),
  notification_url: z.string().url(),
})
export type SignatureParams = ZodSimplify<typeof SignatureParamsSchema>

export type UploadSignature = {
  fileId: FileId
  signature: string
  params: SignatureParams
}

export type FileManagerInstance = {
  addQuickframeTags: (filePath: string, tags: QuickframeTags) => Promise<string>
  downloadFile: (
    publicId: FileStorageKey,
    fileResourceType: FileResourceType,
    fileName: string
  ) => Promise<string>
  getAsset: (
    publicId: FileStorageKey,
    fileResourceType: FileResourceType
  ) => Promise<Asset>
  getAssetDownloadUrl: (
    publicId: FileStorageKey,
    options: AssetDownloadUrlOptions
  ) => string
  getImageDownloadUrl: (
    publicId: FileStorageKey,
    options: ImageUrlOptions
  ) => string
  getUploadSignature(
    fileArea: FileArea,
    fileName: string,
    filesApiBaseUrl: string,
    options: UploadOptions
  ): UploadSignature
  getVideoUrl: (
    publicId: FileStorageKey,
    options: VideoTransformationOptions
  ) => Promise<string>
  getUploadUrl: (
    fileResourceType: FileResourceType,
    cloudinaryApiBaseUrl: string
  ) => string
  overwriteFile: (
    filePath: string,
    publicId: FileStorageKey,
    options?: UploadApiOptions
  ) => Promise<FileUploadResponse>
  pingStorage: () => Promise<boolean>
  signApiParameters: (
    validForSeconds?: number,
    params?: Record<string, string | number>
  ) => {
    params: Record<string, string | number>
    timestamp: number
    signature: string
  }
  updateMetadata: (
    publicId: FileStorageKey,
    metaData: StructuredMetadata,
    resourceType: FileResourceType
  ) => Promise<Asset>
  uploadFile: (
    filePath: string,
    options?: UploadApiOptions
  ) => Promise<FileUploadResponse>
  verifyNotificationSignature: (
    input: VerifyNotificationSignatureInput
  ) => boolean
}
