import {
  DateStringSchema,
  EnumBuilder,
  type ZodSimplify,
} from '@mntn-dev/utility-types'
import { z } from 'zod'
import { FileResourceTypeSchema } from '../common/resource.ts'
import { AccessModeSchema, DeliveryTypeSchema } from '../common/upload-enums.ts'
export const [ModerationStates, ModerationStatusSchema] = EnumBuilder(
  'approved',
  'rejected'
)

export const ModerationRecordSchema = z.object({
  status: ModerationStatusSchema,
  kind: z.string(),
})

export type ModerationRecord = ZodSimplify<typeof ModerationRecordSchema>

export const SuccessEventInfoSchema = z.object({
  public_id: z.string(),
  version: z.number(),
  signature: z.string(),
  width: z.number().optional(),
  height: z.number().optional(),
  format: z.string().optional(),
  resource_type: FileResourceTypeSchema,
  created_at: DateStringSchema,
  tags: z.array(z.string()),
  bytes: z.number(),
  type: DeliveryTypeSchema,
  etag: z.string(),
  url: z.string(),
  secure_url: z.string(),
  original_filename: z.string().optional(),
  placeholder: z.boolean(),
  access_mode: AccessModeSchema,
  original_extension: z.string().optional(),
  thumbnail_url: z.string().url().optional(),
  duration: z.number().optional(),
  moderation: z.array(ModerationRecordSchema).optional(),
  display_name: z.string().optional(),
})

export type SuccessEventInfo = ZodSimplify<typeof SuccessEventInfoSchema>

export const SuccessEventSchema = z.object({
  event: z.literal('success'),
  info: SuccessEventInfoSchema,
})

export type SuccessEvent = ZodSimplify<typeof SuccessEventSchema>
