import {
  DeliverableIdSchema,
  FileIdSchema,
  ProjectIdSchema,
  TeamIdSchema,
} from '@mntn-dev/domain-types'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const QuickframeTagsSchema = z.object({
  assetFileUuid: FileIdSchema,
  brandUuid: TeamIdSchema,
  projectUuid: ProjectIdSchema,
  deliverableUuid: DeliverableIdSchema,
})

export type QuickframeTags = ZodSimplify<typeof QuickframeTagsSchema>
